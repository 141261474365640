import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { ErrorMessageHandler, LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent, LocaleService } from "@smallstack/i18n-components";
import { PasswordFieldComponent } from "../password-field/password-field.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "smallstack-user-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, I18nComponent, LoadingElementDirective, PasswordFieldComponent]
})
export class PasswordResetComponent {
  @Input()
  public token: string;

  @Input()
  public redirectUrl: string;

  public newPassword: string;
  public passwordResetMailAddress: string;
  public error: string;
  public message: string;
  public successfullyResetPassword = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private apiClient: AxiosApiClient,
    private errorMessageHandler: ErrorMessageHandler,
    private localeService: LocaleService,
    activatedRoute: ActivatedRoute
  ) {
    void activatedRoute.queryParams?.subscribe((queryParams: any) => {
      this.token = queryParams.token;
    });
  }

  public resetPassword() {
    return async (): Promise<void> => {
      this.error = undefined;
      try {
        await this.apiClient.get(UsersApi).setPasswordWithResetToken({
          resetPasswordWithTokenBody: { password: this.newPassword, token: this.token },
          translate: this.localeService.getCurrentLocale()
        });
        this.message = "@@components.passwordreset.passwordset";
        this.cdr.markForCheck();
      } catch (e) {
        this.error = this.errorMessageHandler.handleMessage(e);
        this.cdr.markForCheck();
      }
    };
  }

  public sendPasswordResetMail() {
    return async (): Promise<void> => {
      this.error = undefined;
      try {
        const redirectUrl = this.redirectUrl ? this.redirectUrl : window.location.href + "?token=";
        await this.apiClient.get(UsersApi).sendResetPasswordMail({
          resetPasswordBody: { email: this.passwordResetMailAddress, redirectUrl },
          translate: this.localeService.getCurrentLocale()
        });
        this.message = "@@components.passwordreset.passwordresetmailsent";
        this.cdr.markForCheck();
      } catch (e) {
        this.error = this.errorMessageHandler.handleMessage(e);
        this.cdr.markForCheck();
      }
    };
  }
}
