@if (error) {
  <div class="text-error">
    <smallstack-i18n [data]="error"></smallstack-i18n>
  </div>
}

@if (message) {
  <div class="alert alert-success mb-4" data-test="success-message">
    <smallstack-i18n [data]="message"></smallstack-i18n>
  </div>
}

<div class="flex flex-col gap-10">
  @if (token) {
    <smallstack-user-password-field (passwordChange)="newPassword = $event"> </smallstack-user-password-field>
    <button class="btn btn-primary" [loadingFn]="resetPassword()" data-test="passwordreset-setpassword-btn">
      <smallstack-i18n data="@@components.passwordreset.title"></smallstack-i18n>
    </button>
    <!-- <div>
    <smallstack-i18n data="@@components.passwordreset.newtoken.text"></smallstack-i18n>:
    <span class="link" (click)="token = undefined">
      <smallstack-i18n data="@@components.passwordreset.newtoken.link"></smallstack-i18n>
    </span>
  </div> -->
  } @else {
    <smallstack-i18n data="@@components.passwordreset.verificationtext"></smallstack-i18n>
    <input
      type="text"
      placeholder="E-Mail Adresse"
      class="input input-bordered"
      [(ngModel)]="passwordResetMailAddress"
      data-test="passwordreset-email-input"
    />
    <button class="btn btn-primary shadow" [loadingFn]="sendPasswordResetMail()" data-test="passwordreset-submit-btn">
      <smallstack-i18n data="@@components.passwordreset.title"></smallstack-i18n>
    </button>
  }
</div>
